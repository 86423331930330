import { cn } from '@lemonenergy/lemonpie-3'

const FormActions = ({
  children,
  className,
  ...props
}: React.HTMLProps<HTMLDivElement>) => (
  <footer
    className={cn('flex flex-row mt-auto pb-24 sm:mt-0 gap-16', className)}
    {...props}
  >
    {children}
  </footer>
)

export default FormActions
